import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import { FilteredHistory } from "./types";

export function getFormattedTime(timestamp: string | undefined) {
  if (!timestamp) return "";
  const formattedTime = moment(timestamp).format("HH:mm");
  return formattedTime;
}

export function getErrorMessage(error: any) {
  if (axios.isCancel(error) || error.name === "AbortError")
    return "Response generation aborted";
  if (error.response?.data) {
    const { message, detail } = error.response.data;
    if (message) {
      return message;
    }
    if (detail) {
      if (typeof detail === "string") {
        return detail;
      }
      return detail[0]?.msg ?? "";
    }
  }

  return error.message;
}

export function filterHistory(convers_ids: any) {
  const filteredHistory: FilteredHistory = {};
  const todayEntries = [];
  const prev7DaysEntries = [];
  const prev30DaysEntries = [];
  const olderEntries = [];

  const currentDate = moment(); // Current date

  // Iterate over the entries and filter based on timestamps
  for (const key in convers_ids) {
    const timestamp = convers_ids[key].timestamp;
    if (timestamp !== "null") {
      const parsedTimestamp = moment(timestamp);

      if (parsedTimestamp.isSame(currentDate, "day")) {
        todayEntries.push({ key, ...convers_ids[key] });
      } else if (
        parsedTimestamp.isAfter(currentDate.clone().subtract(7, "days"))
      ) {
        prev7DaysEntries.push({ key, ...convers_ids[key] });
      } else if (
        parsedTimestamp.isAfter(currentDate.clone().subtract(30, "days"))
      ) {
        prev30DaysEntries.push({ key, ...convers_ids[key] });
      } else {
        olderEntries.push({ key, ...convers_ids[key] });
      }
    }
  }
  
  if (!isEmpty(todayEntries)) {
    todayEntries.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf());
    filteredHistory["Today"] = todayEntries;
  }
  if (!isEmpty(prev7DaysEntries)) {
    prev7DaysEntries.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf());  
    filteredHistory["Previous 7 Days"] = prev7DaysEntries;
  }
  if (!isEmpty(prev30DaysEntries)) {
    prev30DaysEntries.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf());  
    filteredHistory["Previous 30 Days"] = prev30DaysEntries;
  }
  if (!isEmpty(olderEntries)) {
    olderEntries.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf());
    filteredHistory["Older"] = olderEntries;
  }

  console.log(filteredHistory);
  return filteredHistory;
}
