// src/pages/Home.tsx
import React, { useState } from 'react';
import FloatingButton from '../components/FloatingButton';
import OverlayChatbot from '../components/OverlayChatbot';
import { ReactComponent as CaptainFrankSvg } from "../assets/CaptainFrank.svg";

const WhatsLegalChat: React.FC = () => {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const openChatbot = () => setIsChatbotOpen(true);
  const closeChatbot = () => setIsChatbotOpen(false);

  return (
    <> 
      <FloatingButton 
        onClick={openChatbot} 
        icon={<CaptainFrankSvg style={{ width: 35 }} />} 
      />
      <OverlayChatbot isOpen={isChatbotOpen} onClose={closeChatbot} />
    </>
  );
};

export default WhatsLegalChat;
