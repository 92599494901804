// FloatingButton.tsx
import React from 'react';
import styles from './css/FloatingButton-styles.module.css';

interface FloatingButtonProps {
  onClick: () => void;
  icon?: React.ReactNode;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ onClick, icon = '💬' }) => {
  return (
    <button className={`${styles.overlayFloatingButton} pulse`} onClick={onClick}>
      {icon}
    </button>
  );
};

export default FloatingButton;
