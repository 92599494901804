export const LANDING_PARA =
  "WhatsLegal befindet sich in der Betaphase zum Testen und ist beschränkt auf deutsche Gesetze. Mit der Nutzung der WhatsLegal App erklären Sie sich mit den AGB's https://www.whatslegal.app/agbs sowie den Nutzungsbedingungen https://www.whatslegal.app/ nutzungsbedingungen einverstanden. WhatsLegal übernimmt keine Gewähr für die Richtigkeit der Antworten. WhatsLegal ist und ersetzt keine anwaltliche Beratung.";
// export const BASE_URL = "https://chat.whatslegal.ai/";
export const BASE_URL = "https://captainfrank.whatslegal.ai/";
export const LOGIN_ENDPOINT = "login";
export const SIGNUP_ENDPOINT = "register";

export const dataSourceMenu = ["DS 1", "DS 2", "DS 3"];

export const modelSelectorMenu = [
  "Assistent 1",
  "Assistent 2",
  "Assistent 3",
  "Assistent 4",
];
